@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg-blue-800 { background-color: #1d3557; }
.text-orange-500 { color: #ffa726; }
.custom-logo {
  @apply h-[150px] w-auto; /* Taille personnalisée avec Tailwind */
  @apply mt-6; /* Ajustement vertical si nécessaire */
 /* Animation de rotation */
}

.loader {
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}